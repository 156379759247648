import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import Calendar from "../../images/calendar.inline.svg";
import Heading from "./heading";

function Article({ blogPost: { heroImage, publishDate, title, description, slug } }) {
  return (
    <div className="mx-8 flex-1 mb-12">
      <div className="relative w-full h-0 pt-3/5 rounded shadow overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full">
          <Img fluid={heroImage.fluid} />
        </div>
      </div>
      <div className="relative w-4/5 bg-white rounded shadow -mt-40 min-h-40 mx-auto p-6 text-center">
        <div className="flex justify-center text-gray-700 leading-tight mb-4">
          <Calendar className="mr-2" /> {publishDate}
        </div>
        <h3 className="font-bold text-teal-500 text-xl mb-2">{title}</h3>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
        />
        <div className="mb-2">
          <Link to={`/blog/${slug}`}>
            <span className="button inline-block text-white font-bold py-2 px-8 rounded-lg uppercase">
              Read more
            </span>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .button {
          background-image: linear-gradient(to bottom left, #8ae6e2, #54b4a4);
        }
      `}</style>
    </div>
  );
}

function RecentBlogArticles({ heading, limit, omitSlug }) {
  const { blogPosts } = useStaticQuery(graphql`
    query {
      blogPosts: allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 10) {
        edges {
          node {
            slug
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            publishDate(formatString: "MMMM Do, YYYY")
            heroImage {
              fluid(maxWidth: 576, maxHeight: 346, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const filteredBlogPosts = blogPosts.edges
    .filter(({ node: { slug } }) => slug !== omitSlug)
    .slice(0, limit);

  return (
    <div className="container">
      { heading && (
        <Heading centered>{ heading }</Heading>
      )}
      <div className="flex flex-col flex-wrap md:flex-row -mx-8">
        { filteredBlogPosts.map(({ node: blogPost })=> (
          <Article key={blogPost.slug} blogPost={blogPost} />
        ))}
      </div>
    </div>
  );
}

RecentBlogArticles.propTypes = {
  heading: PropTypes.string,
  limit: PropTypes.number,
  omitSlug: PropTypes.bool,
};

RecentBlogArticles.defaultProps = {
  heading: null,
  limit: 2,
  omitSlug: null,
};

export default RecentBlogArticles;
