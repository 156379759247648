import React from "react";
import BackgroundImage from "gatsby-background-image";
import classnames from "classnames";
import css from "styled-jsx/css";

function Hero({ children, imageFile, screen, overlay }) {
  let className = '', styles = '';

  if (overlay) {
    const resolvedCss = css.resolve`background-image: ${overlay};`;
    className = resolvedCss.className;
    styles = resolvedCss.styles;
  }

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageFile.fluid}
      className={classnames(className, { "min-h-screen": screen })}
      preserveStackingContext={!!overlay}
    >
      {children}
      {styles}
    </BackgroundImage>
  );
}

export default Hero;
