import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import MountainClouds from "../../images/mountain-clouds.inline.svg";
import HillClouds from "../../images/hill-clouds.inline.svg";

function CallToAction() {
  const { theme: { callToActionText, callToActionButtonText } } = useStaticQuery(graphql`
    query {
      theme: contentfulTheme {
        callToActionText
        callToActionButtonText
      }
    }
  `);

  return (
    <div>
      <section className="relative flex justify-between items-end pt-4 min-h-40">
        <MountainClouds className="-ml-12 md:ml-0 w-2/5 md:w-1/4 h-auto" />
        <HillClouds className="-mr-12 md:mr-0 w-1/2 md:w-1/3 h-auto" />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white">
          <h3 className="pb-4 md:text-xl font-bold">{ callToActionText }</h3>
          <a href="https://calendly.com/sara-elevate-coaching/intro" target="_blank" className="bg-white text-teal-500 font-bold py-2 px-8 rounded-lg uppercase">{ callToActionButtonText }</a>
        </div>
      </section>
      <style jsx>{`
        section {
          background-image: linear-gradient(to right, #8ae6e2, #54b4a4);
        }
      `}</style>
    </div>
  );
}

export default CallToAction;
