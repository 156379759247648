import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-body text-gray-800">
      <header className="absolute w-full z-10">
        <nav className="container py-8 text-lg font-bold">
          <Link to="/">
            <span className="text-teal-300">Elevate</span>
            <span className="text-white">Coaching</span>
          </Link>
        </nav>
      </header>

      <main className="flex-1 z-0">{children}</main>

      <footer className="bg-gray-800 text-white text-center text-sm p-8 flex justify-center gap-16">
        <div><a href="/privacy-policy.html">Privacy Policy</a></div>
        <div>© {new Date().getFullYear()} Elevate Coaching, LLC</div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
