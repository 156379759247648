import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Separator from "../../images/separator.inline.svg";

function Heading({ tag: Tag, children, centered }) {
  return (
    <div className="mb-12">
      <Tag
        className={classnames(
          "font-display font-bold text-3xl xl:text-4xl text-teal-500 mb-2",
          { "text-center": centered }
        )}
      >
        {children}
      </Tag>
      <Separator className={classnames({ "mx-auto": centered })} />
    </div>
  );
};

Heading.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
};

Heading.defaultProps = {
  tag: 'h2',
  centered: false,
};

export default Heading;
